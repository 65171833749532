<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2>İş Ortakları için İleti Yönetim Sistemi</h2>

        <div class="staticbox">
          <div class="p70">
            <p>
              CRM yazılım hizmeti, toplu SMS gönderimi, toplu e-posta gönderimi,
              çağrı merkezi hizmeti ve benzeri hizmetler veren bir firma iseniz,
              İYS iş ortağı olarak İYS API lisansı kullanım hakkı elde edebilir
              ve kendi müşterilerinize İYS entegrasyon hizmeti sunabilirsiniz.
            </p>

            <p>
              İYS İş Ortağı olmak için
              <router-link to="/iletisim" class="blue">bize ulaşın</router-link
              >.
            </p>

            <div class="full mtmb">
              <img src="@/assets/img/icons/hs-03.svg" alt />
            </div>

            <h2>İş Ortağı Modeli</h2>

            <div class="full mtmb">
              <img src="@/assets/img/hero/is_ortakligi.svg" alt />
            </div>

            <h2 class="mt2">İş Ortağı Yetkilendirme</h2>
            <p>
              Bir iş ortağı ancak bir hizmet sağlayıcı tarafından
              yetkilendirildiğinde, bu hizmet sağlayıcının verisine erişim
              yetkisi kazanır.
            </p>
            <p>
              İş ortağı yetkilendirme işlemi, İYS web sitesi üzerinden kurumsal
              giriş yapılarak gerçekleştirilebilir. Bir hizmet sağlayıcı birden
              çok iş ortağını yetkilendirebilir. Benzer biçimde yetkilendirme,
              veriye erişim iletişim kanalı bazında (mesaj, arama, e-posta ve
              mutabakat servisi) yapılabilir. Ancak her hizmet sağlayıcı
              mutabakat servisi için sadece bir iş ortağı yetkilendirebilir.
            </p>
          </div>
          <div class="p70">
            <h2>İYS İş Ortakları</h2>

            <div class="isortaklogo center mb2">
              <a
                v-for="item in integrators"
                :key="item.id"
                :href="item.link"
                target="_blank"
              >
                <img
                  :src="
                    'https://cdn.iys.org.tr/assets/img/isortaklari' + item.logo
                  "
                  alt
                />
              </a>
              <!--  <a href="https://www.mysoft.com.tr/"  target="_blank" ><img src="@/assets/img/isortaklari/mysoft-logo.svg" alt /></a>
              <a href="https://www.mobilpark.biz/" target="_blank" ><img src="@/assets/img/isortaklari/mobilpark-logo.svg" alt /></a>
              <a href="https://www.uzmancrm.com/" target="_blank" ><img src="@/assets/img/isortaklari/uzmancrm-logo.png" alt /></a>
              <a href="https://www.useinsider.com/" target="_blank" ><img src="@/assets/img/isortaklari/insider-logo.svg" alt /></a>
              <a href="http://www.biotekno.com.tr/" target="_blank" ><img src="@/assets/img/isortaklari/biotekno-logo.svg" alt /></a>
              <a href="http://www.egebilgi.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/egebilgi-logo.png" alt /></a>
              <a href="https://www.relateddigital.com/tr" target="_blank"><img src="@/assets/img/isortaklari/related-logo.png" alt /></a>
              <a href="https://useinbox.com/tr" target="_blank"><img src="@/assets/img/isortaklari/inbox-logo.svg" alt /></a>
              <a href="http://www.turatel.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/turatel-logo.svg" alt /></a>
              <a href="https://www.mobildev.com/" target="_blank"><img src="@/assets/img/isortaklari/mobildev-logo.svg" alt /></a>
              <a href="https://www.uyumsoft.com/" target="_blank"><img src="@/assets/img/isortaklari/uyumsoft-logo.png" alt /></a>
              <a href="https://cronoc.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/cronoc-logo.png" alt /></a>
              <a href="https://krea.digital/tr/" target="_blank"><img src="@/assets/img/isortaklari/krea-logo.svg" alt /></a>
              <a href="https://www.tani.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/tani-logo.svg" alt /></a>
              <a href="https://codec.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/codec-logo.png" alt /></a>
              <a href="https://www.rnd.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/rnd-logo.png" alt /></a>
              <a href="https://ester.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/ester-logo.svg" alt /></a>
              <a href="https://www.arge24.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/arge24-logo.svg" alt /></a>
              <a href="https://www.bienteknoloji.com/" target="_blank"><img src="@/assets/img/isortaklari/bien-logo.svg" alt /></a> 
              <a href="https://www.netgsm.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/netgsm-logo.svg" alt /></a>
              <a href="https://dengage.com/" target="_blank"><img src="@/assets/img/isortaklari/dengage-logo.svg" alt /></a>
              <a href="http://www.ajansiletisim.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/iletisim-logo.svg" alt /></a>
              <a href="http://www.smartmessage.com" target="_blank"><img src="@/assets/img/isortaklari/smartmessage-logo.svg" alt /></a>
              <a href="http://www.dataport.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/dataport-logo.svg" alt /></a>
              <a href="https://www.inspark.com/" target="_blank"><img src="@/assets/img/isortaklari/inspark-logo.svg" alt /></a>
              <a href="https://www.revotas.com/tr/" target="_blank"><img src="@/assets/img/isortaklari/revotas-logo.svg" alt /></a>
              <a href="https://www.setrow.com/tr/" target="_blank"><img src="@/assets/img/isortaklari/setrow-logo.svg" alt /></a>
              <a href="https://www.figensoft.com/" target="_blank"><img src="@/assets/img/isortaklari/figensoft-logo.svg" alt /></a>
              <a href="https://www.kivacrm.com/tr" target="_blank"><img src="@/assets/img/isortaklari/kivacrm-logo.svg" alt /></a>
              <a href="https://www.doruk.net.tr/" target="_blank"><img src="@/assets/img/isortaklari/doruknet-logo.svg" alt /></a>
              <a href="https://www.ttmesaj.com/" target="_blank"><img src="@/assets/img/isortaklari/ttmesaj-logo.svg" alt /></a>
              <a href="https://www.wicasa.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/wicasa-logo.svg" alt /></a>
              <a href="https://www.sanalsantral.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/sanalsantral-logo.svg" alt /></a>
              <a href="http://veribase.com/" target="_blank"><img src="@/assets/img/isortaklari/veribase-logo.svg" alt /></a>
              <a href="https://www.mutlucell.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/mutlucell-logo.svg" alt /></a>
              <a href="https://www.kns.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/kns-logo.svg" alt /></a>
              <a href="https://coflow.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/coflow-logo.svg" alt /></a>
              <a href="https://www.izibiz.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/izibiz-logo.png" alt /></a>
              <a href="https://www.kobikom.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/kobikom-logo.svg" alt /></a>
              <a href="https://www.albil.com.tr" target="_blank"><img src="@/assets/img/isortaklari/albil-logo.svg" alt /></a>
              <a href="https://www.directiq.com/tr/" target="_blank"><img src="@/assets/img/isortaklari/directiq-logo.svg" alt /></a>
              <a href="http://www.turkfinansal.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/turkfinansal-logo.svg" alt /></a>
              <a href="http://asistiletisim.com/" target="_blank"><img src="@/assets/img/isortaklari/asistbt-logo.svg" alt /></a>
              <a href="https://mertyed.com/" target="_blank"><img src="@/assets/img/isortaklari/mertyed-logo.svg" alt /></a>
              <a href="https://setup34.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/setup34-logo.svg" alt /></a>
              <a href="http://www.crssoft.com/" target="_blank"><img src="@/assets/img/isortaklari/crssoft-logo.svg" alt /></a>
              <a href="https://www.verimor.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/verimor-logo.svg" alt /></a>
              <a href="https://www.telsam.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/telsam-logo.png" alt /></a>
              <a href="https://www.veyosis.com/" target="_blank"><img src="@/assets/img/isortaklari/veyosis-logo.svg" alt /></a>
              <a href="http://www.didtelekom.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/didtelekom-logo.svg" alt /></a>
              <a href="https://echoccs.com/" target="_blank"><img src="@/assets/img/isortaklari/echo-logo.svg" alt /></a>
              <a href="http://www.oztekhaberlesme.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/oztek-logo.svg" alt /></a>
              <a href="https://www.netmera.com/" target="_blank"><img src="@/assets/img/isortaklari/netmera-logo.svg" alt /></a>
              <a href="http://www.goldmesaj.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/goldmesaj-logo.svg" alt /></a>
              <a href="https://www.messagetosend.com/" target="_blank"><img src="@/assets/img/isortaklari/messagetosend-logo.svg" alt /></a>
              <a href="https://www.ticimax.com/" target="_blank"><img src="@/assets/img/isortaklari/ticimax-logo.svg" alt /></a>
              <a href="https://makdos.com/tr/" target="_blank"><img src="@/assets/img/isortaklari/makdos-logo.png" alt /></a>
              <a href="https://www.iqvia.com/" target="_blank"><img src="@/assets/img/isortaklari/iqvia-logo.svg" alt /></a>
              <a href="https://www.tempocagri.com/" target="_blank"><img src="@/assets/img/isortaklari/tempo-logo.svg" alt /></a> 
              <a href="http://www.crmakademi.net/" target="_blank"><img src="@/assets/img/isortaklari/crmakademi-logo.svg" alt /></a> 
              <a href="https://www.pusula.net.tr/" target="_blank"><img src="@/assets/img/isortaklari/uzmanposta-logo.svg" alt /></a> 
              <a href="http://www.polisoft.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/polisoft-logo.svg" alt /></a> 
              <a href="http://www.bcworks.com.tr" target="_blank"><img src="@/assets/img/isortaklari/bcworks-logo.svg" alt /></a> 
              <a href="http://www.dop.com.tr/" target="_blank"><img src="@/assets/img/isortaklari/onayla-logo.svg" alt /></a> 
              -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <social />

    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";

import Social from "../genel/Social";

export default {
  name: "IsOrtakligi",
  components: {
    Social,
  },

  data() {
    return {
      integrators: [],
    };
  },

  created() {
    this.$store.dispatch("applicant/get_integrators").then((response) => {
      this.integrators = [...response.payload].sort((a, b) => {
        if (a.marka?.toLowerCase() < b.marka?.toLowerCase()) {
          return -1;
        }
        if (a.marka?.toLowerCase() > b.marka?.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.$env.CONSOLE.warn("MY INTEGRATORS:", this.integrators);
    });
  },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_NOTIFY_REQUESTER_TYPE"]),

    TANITIM_FILMI_CLICKED() {
      this.$router.push("/tanitim");
    },
  },
};
</script>

<style scoped>
.isortaklogo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.isortaklogo img {
  object-fit: contain;
  object-position: center;
  height: 45px;
}

.isortaklogo a {
  display: flex;
  align-items: center;
  width: 21%;
  padding: 15px;
  border-radius: 10px;
  margin: 15px;
  border: 1px solid rgb(245, 245, 245);
}

.isortaklogo a:hover {
  background: #fdfbfa;
  box-shadow: 0 10px 25px -15px #00000040;
  border: 1px solid #fff;
}

ul.list {
  margin: 10px 0px 15px 60px;
  text-align: left;
}

ul.list li {
  display: list-item;
  list-style: disc !important;
  text-align: left;
}
</style>
